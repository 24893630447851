<template lang="pug">
div
  .header
    NuxtLink(:to="{ name: 'home', query: { utm_source: 'unity-league', utm_medium: 'landing-page', coupon: 'LEAGUER' } }")
      img.logo(src="~/assets/landing-pages/marketing/unity-league/box-magenta-logo.png" alt="Unity League")

  .content

    .row.magenta

      .hero
        img(src="~/assets/landing-pages/marketing/unity-league/hero.png" )

        .block

          h1
            | Oi, Leaguer!
            br
            | Que tal adicionar ainda mais cuidado na sua rotina?

          p
            | A Magenta não é só uma box de beleza.
            br
            | gente descomplica o universo do skincare e da make, salva dias ruins e ajuda as pessoas a serem orgulhosamente mais elas

          .spacer

          .centered
            b Quer fazer parte? Use o cupom:

            br

            NuxtLink.cta(:to="ctaDestination") LEAGUER

        .spacer

    .wiggles

    .spacer

    .row
      .block.left
        .icon
          img(src="~/assets/landing-pages/marketing/unity-league/icon-1.png")

        h3.magenta Como fazemos isso?
        h2
          | Oferecendo uma&#32;
          span.magenta experiência de beleza completa, surpreendente e ultra personalizada


        p
          | Se tornando Magenter, você vai receber todo mês uma seleção de produtos que fazem sentido pro seu&#32;
          b perfil de beleza.&#32;
          | Sim, nós acertamos o tom da base, a textura do hidratante, os ativos da máscara capilar e até a fragrância do body splash!

    .spacer

    .row
      .block.right
        .icon
          img(src="~/assets/landing-pages/marketing/unity-league/icon-2.png")

        h3.magenta O que vem na Box Magenta?
        h2 5 produtos em tamanho real


        p
          | Além disso, trabalhamos com as&#32;
          b melhores marcas do mercado&#32;
          | e tomamos o cuidado de enviar&#32;
          b itens variados a cada box
          | , para que você possa aproveitar todos os seus produtos sem desperdício ou acúmulo. Tudo, né?


    .spacer

    .wiggles-inverted

    .row.magenta
      .block.centered

        .spacer

        h3 A Box Magenta é o clube de quem ama beleza e quer se cuidar de um jeito mais prático, inteligente e divertido.

        .spacer

        .centered
          | Está esperando o que pra entrar?
          br
          b Um cupom de desconto, talvez??

        .spacer

        .centered
          | Tem R$30 OFF aqui:

        NuxtLink.cta(:to="ctaDestination") LEAGUER

        .spacer

        .icon
          img(src="~/assets/landing-pages/marketing/unity-league/icon-3.png")

      .spacer

    .wiggles
</template>
<script setup lang="ts">
definePageMeta({
  layout: 'landing-page',
})

const ctaDestination = {
  name: 'subscription-register',
  query: {
    utm_source: 'unity-league',
    utm_medium: 'landing-page',
    coupon: 'LEAGUER',
  },
}
</script>
<style lang="sass" scoped>
@import "~/assets/styles/mixins.sass"

.header
  display: flex
  justify-content: center
  align-items: center
  background-color: #eb5a32
  padding: 16px

  img.logo
    width: 300px

    @include mobile
      width: 172px

.cta
  display: block
  vertical-align: top
  width: 100%
  max-width: 480px
  text-decoration: none
  color: #e6007e
  background-color: rgba(255, 255, 255, 1)
  border-radius: 30px
  margin: 32px auto
  padding: 16px 32px
  font-weight: 800
  font-size: 22px
  text-align: center

.spacer
  height: 48px

.content
  display: flex
  flex-direction: column
  gap: 0
  align-items: stretch
  justify-content: flex-start
  font-size: 18px

  .row
    &.magenta
      background-color: #e6007e
      color: white

      h1, h2, h3
        color: white

  .block
    display: flex
    position: relative
    flex-direction: column
    align-items: stretch
    justify-content: flex-start
    width: 100%
    max-width: 1240px
    margin: 0 auto
    padding: 0 16px

    p, h1, h2, h3
      margin: 0 0

      &.magenta, span.magenta
        color: #e6007e

    p, h3
      margin: 8px 0

    &.left
      .icon, p, h1, h2, h3
        padding-right: 30%

    &.right
      .icon, p, h1, h2, h3
        padding-left: 30%

    @include mobile
      &.left, &.right
        .icon, p, h1, h2, h3
          padding: 0

      p
        text-align: justify

  .centered
    text-align: center

  .hero
    display: flex
    width: 100%
    max-width: 1240px
    margin: 0 auto
    flex-direction: row
    justify-content: center
    align-items: center
    gap: 48px
    text-align: left
    background-color: #e6007e

    img
      width: 100%
      max-width: 480px
      margin: 48px auto

    @include tablet
      flex-direction: column
      gap: 0

      img
        max-width: 100%
        margin: 0 auto
        border-radius: 0

  .icon
    text-align: center
    margin-bottom: 48px

    img
      width: 180px
      height: auto

  .wiggles, .wiggles-inverted
    display: block
    margin: 0 auto
    background-repeat: repeat no-repeat
    background-size: auto
    height: 24px
    width: 100%

  .wiggles
    background-image: url('~/assets/landing-pages/marketing/unity-league/wiggles.svg')

  .wiggles-inverted
    background-image: url('~/assets/landing-pages/marketing/unity-league/wiggles-inverted.svg')
</style>
